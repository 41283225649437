<template>
  <Default>
    <Stack id="layout" tag="main" direction="col" gap="none" class="text-charcoal-default">
      <Breadcrumbs :items="breadcrumbs" class="w-full px-xs pd:mx-md mt-2xs md:mt-xs" />
      <Stack direction="col" :breakpoints="{ direction: { md: 'row' } }" class="w-full p-none pb-sm md:p-md">
        <Stack class="w-full md:w-[290px] p-xs md:p-none">
          <MyAccountMenu />
        </Stack>
        <Stack class="w-full">
          <slot />
        </Stack>
      </Stack>
    </Stack>
  </Default>
</template>

<script setup lang="ts">
import Breadcrumbs from "~/components/UI/Breadcrumbs/Breadcrumbs.vue";
import Default from "~/layouts/default.vue";
import MyAccountMenu from "~/components/MyAccount/MyAccountMenu/MyAccountMenu.vue";
import { BreadcrumbItemProps } from "~/components/UI/Breadcrumbs/breadcrumbTypes";
import { capitalizeWords } from "mkm-avengers";

useHead({
  bodyAttrs: {
    class: "bg-neutral-lighter",
  },
});

const route = useRoute();

const breadcrumbs = computed(() => {
  const breadcrumbs: BreadcrumbItemProps[] = [{ title: "My Account", href: "/my-account" }];
  const childPagePath = route.path.split("/")[2];

  let title = "";

  if (childPagePath) {
    switch (childPagePath) {
      case "financial":
        title = "Invoices and Statement";
        break;
      case "orders":
        title = "My Orders";
        break;
      default:
        title = capitalizeWords(childPagePath.replace("-", " "));
        break;
    }

    breadcrumbs.push({ title: title, href: route.path });
  }

  return breadcrumbs;
});
</script>
