<template>
  <Stack direction="col" class="w-full">
    <div class="flex md:hidden w-full">
      <MobileMenu :items="menuItems" />
    </div>
    <div class="hidden md:flex w-full">
      <DesktopMenu :items="menuItems" />
    </div>
  </Stack>
</template>

<script lang="ts" setup>
import DesktopMenu from "~/components/MyAccount/MyAccountMenu/DesktopMenu/DesktopMenu.vue";
import MobileMenu from "~/components/MyAccount/MyAccountMenu/MobileMenu/MobileMenu.vue";
import { MyAccountMenuItem } from "~/components/MyAccount/MyAccountMenu/myAccountMenuTypes";
import { getMyAccountMenuItems } from "~/components/MyAccount/MyAccountMenu/myAccountMenuItems";

const { user, isAdmin, isCredit } = useUser();

const menuItems = ref<MyAccountMenuItem[]>([]);

watch(
  user,
  () => {
    if (user.value) {
      menuItems.value = getMyAccountMenuItems(isAdmin.value, isCredit.value);
    }
  },
  { immediate: true },
);
</script>
