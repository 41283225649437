<template>
  <Stack direction="col" class="w-[290px]">
    <SkeletonLoader v-if="!displayMenu" :display-logo="false" class="h-[296px] px-sm py-xs">
      <Stack class="w-1/3 h-sm bg-grey-default rounded-sm" />
      <Stack class="w-2/3 h-sm bg-grey-default rounded-sm" />
      <Stack class="w-1/3 h-sm bg-grey-default rounded-sm" />
      <Stack class="w-1/2 h-sm bg-grey-default rounded-sm" />
      <Stack class="w-1/2 h-sm bg-grey-default rounded-sm" />
      <Stack class="w-1/3 h-sm bg-grey-default rounded-sm" />
    </SkeletonLoader>

    <nav v-if="displayMenu" class="w-full px-sm py-xs">
      <ul>
        <li v-for="menuItem in items" :key="menuItem.key" class="w-full pb-sm">
          <NuxtLink :to="menuItem.href">
            <Text tag="span" :weight="route.path === menuItem.href ? 'bold' : 'semi'">{{ menuItem.label }}</Text>
          </NuxtLink>
        </li>
        <li>
          <button @click="handleSignOut">
            <Text tag="span" weight="semi" class="text-red-dark">Sign out</Text>
          </button>
        </li>
      </ul>
    </nav>

    <MobileAppPromo v-if="displayPromo" src="/account/desktop-mobile-promotion.png" alt="Manage Your Credit Account" />

    <RewardsClub v-if="displayRewardsClub" />
  </Stack>
</template>

<script lang="ts" setup>
import { DesktopMenuProps } from "./desktopMenuTypes";
import { ROUTES } from "~/helpers/routes/routes";
import { toRefs } from "vue";

const props = defineProps<DesktopMenuProps>();
const { items } = toRefs(props);

const { logout } = useUser();
const route = useRoute();

const displayMenu = computed(() => items.value.length > 0);
const displayPromo = computed(() => route.path.includes("financial"));
const displayRewardsClub = computed(() => route.path === ROUTES.ACCOUNT_DASHBOARD);

const handleSignOut = async () => {
  await logout();
};
</script>
